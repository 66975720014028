<template>
  <span class="label "
    :class="[type && light ? 'label--light-' + type : '', type && !light ? 'label-' + type : '', { 'label--inline': inline, 'label--pill': pill }]">
    <slot></slot>
  </span>
</template>

<style lang="scss" scoped>
.label {
  padding: 0;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: #EBEDF3;
  color: #3F4254;
  font-weight: 400;
  font-size: 0.68rem;

  &--inline {
    width: auto;
    padding: 0.10rem 0.70rem;
    border-radius: 0.42rem;
  }

  &--pill {
    border-radius: 2rem;
  }

  // Light style
  &--light {
    &-dark {
      color: #181C32;
      background-color: #D1D3E0;
    }

    &-info {
      color: #8950FC;
      background-color: #EEE5FF
    }

    &-primary {
      color: #3699FF;
      background-color: #E1F0FF;
    }

    &-warning {
      color: #FFA800;
      background-color: #FFF4DE;
    }

    &-danger {
      color: #F64E60;
      background-color: #FFE2E5;
    }

    &-success {
      color: #1BC5BD;
      background-color: #C9F7F5;
    }
  }

  &-info {
    color: #ffffff;
    background-color: #8950FC;
  }

  &-primary {
    color: #FFFFFF;
    background-color: #3699FF;
  }

  &-warning {
    color: #fff;
    background-color: #FFA800;
  }

  &-danger {
    color: #ffffff;
    background-color: #F64E60;
  }

  &-success {
    color: #ffffff;
    background-color: #1BC5BD;
  }
}
</style>

<script>
export default {
  props: {
    inline: Boolean,
    pill: Boolean,
    light: Boolean,
    type: String
  }
}
</script>
