<template>
  <BaseCard>
    <BaseContent v-loading="loading" overflow>
      <template #title>
        <div class="title">
          <span class="font-weight-normal mr-3">{{ project.company.name }}</span>
          <BaseLabel inline pill :type="statusColor[project.status]">{{ status }}</BaseLabel>
        </div>
      </template>
      <template #header>
        <el-row>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Code</span>
                <span class="font-size-sm font-weight-normal pr-3">Name</span>
                <span class="font-size-sm font-weight-normal pr-3">Description</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ project.code }}</span>
                <span class="font-size-sm pl-3">{{ project.name }}</span>
                <span class="font-size-sm pl-3">{{ project.description }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Bill To</span>
                <span class="font-size-sm font-weight-normal pr-3">Ship To</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ project.code }}</span>
                <span class="font-size-sm pl-3">{{ project.name }}</span>
              </div>
            </div>
          </el-col>
          <el-col :lg="8" :md="8" :sm="24">
            <div class="detail">
              <div class="title">
                <span class="font-size-sm font-weight-normal pr-3">Total are</span>
              </div>
              <div class="content">
                <span class="font-size-sm pl-3">{{ taxType }}</span>
              </div>
            </div>
          </el-col>
        </el-row>
      </template>
      <div>
        <BaseTable :data="project.items" :scrollable="false">
          <el-table-column label="Description" min-width="110" prop="description">
            <template slot-scope="slot">
              <span style="white-space: pre-line">{{ slot.row.description }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Quantity" min-width="110" prop="quantity">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.quantity) }}
            </template>
          </el-table-column>
          <el-table-column label="Price" min-width="120" prop="cost">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost) }}
            </template>
          </el-table-column>
          <el-table-column label="Discount" min-width="110" prop="discount">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.discount) }}%
            </template>
          </el-table-column>
          <el-table-column v-if="project.is_inclusive !== 2" label="Tax" min-width="100" prop="tax">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.tax) }}%
            </template>
          </el-table-column>
          <el-table-column label="Total" min-width="100" prop="total">
            <template slot-scope="slot">
              {{ $NumberFormat(slot.row.cost * slot.row.quantity - (slot.row.cost * slot.row.quantity * (slot.row.discount
                / 100))) }}
            </template>
          </el-table-column>
        </BaseTable>
      </div>
      <div class="footer mt-2">
        <div class="note">
          <span></span>
        </div>
        <div class="gf-summary">
          <ul class="summary">
            <li class="summary__item">
              <span class="text">Total Units</span>
              <span class="value">{{ $NumberFormat(project.units) }}</span>
            </li>
            <li v-if="project.is_inclusive !== 2" class="summary__item">
              <span class="text">Subtotal</span>
              <span class="value">{{ $NumberFormat(project.subtotal) }}</span>
            </li>
            <div v-if="project.is_inclusive !== 2">
              <li v-for="(vat, index) in project.vats" :key="index" class="summary__item">
                <span class="text">{{ (project.is_inclusive) ? 'Including' : 'Plus' }} VAT ({{ vat.rate }}%)</span>
                <span class="value">{{ $NumberFormat(vat.value) }}</span>
              </li>
            </div>
            <li class="summary__item">
              <span class="text">Total</span>
              <span class="value">{{ $NumberFormat(project.total) }}</span>
            </li>
          </ul>
        </div>
      </div>
    </BaseContent>
  </BaseCard>
</template>

<style lang="scss" scoped>
.title {
  display: flex;
  align-items: center;
}

.detail {
  display: flex;
  justify-content: center;

  .title,
  .content {
    display: flex;
    flex-direction: column;
  }

  .title {
    align-items: flex-end;

    span {
      border-right: 1px solid #ebedf3;
    }
  }

  .content {
    align-items: flex-start;
  }
}

.footer {
  display: flex;
  justify-content: space-between;

  .note {
    width: 500px;
  }
}
</style>

<script>
import BaseCard from '@/component-v2/BaseCard'
import BaseContent from '@/component-v2/BaseContent'
import BaseLabel from '@/component-v2/BaseLabel'
import BaseTable from '@/component-v2/BaseTable'

import ProjectService from '@/services/v1/ProjectService'
import { PROJECT_STATUS } from '../../../constants/status'
import { TAX_TYPE } from '../../../constants/tax'

export default {
  props: {
    update: Boolean,
    loaded: Boolean,
    updateChild: Boolean
  },
  components: {
    BaseCard,
    BaseContent,
    BaseLabel,
    BaseTable
  },
  data() {
    return {
      loading: false,
      statusColor: {
        Draft: 'dark',
        Active: 'primary',
        Finalized: 'primary',
        'In Progress': 'info',
        Completed: 'success',
        Void: 'warning',
        Deleted: 'danger'
      },

      projectId: this.$route.params.id,
      project: {
        code: '',
        name: '',
        description: '',
        is_inclusive: 0,
        units: 0,
        subtotal: 0,
        total: 0,
        vats: [],
        items: []
      }
    }
  },
  computed: {
    status() {
      const status = Object.keys(PROJECT_STATUS)
      return status[this.project.status]
    },
    taxType() {
      const type = Object.keys(TAX_TYPE)
      return type[this.project.is_inclusive]
    }
  },
  methods: {
    async getProject() {
      try {
        this.loading = true
        const projectService = new ProjectService(this.projectId)
        const result = await projectService.get()
        this.project = result.data
      } catch (error) {
        this.$Error(error)
      } finally {
        this.loading = false
      }
    }
  },
  async mounted() {
    await this.getProject()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Project', route: '' },
      { title: this.project.code }
    ])
  }
}
</script>
